import React, { useEffect, FunctionComponent } from "react";
import redirects from "./redirects.json";

interface RedirectHandlerInterface {
  location: Location;
}

const RedirectHandler: FunctionComponent<RedirectHandlerInterface> = ({ location }) => {
  const handleRedirection = () => {
    if (typeof window !== "undefined") {
      redirects.forEach((redirect: { from: string; to: string }) => {
        if (location.pathname + location.search === redirect.from) window.location.href = redirect.to;
      });
    }
  };

  handleRedirection();

  return null;
};

export default RedirectHandler;
