module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":false},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rexona","short_name":"Rexona","start_url":"/","display":"standalone","icon":"./static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"17fbd99bcacb5de11ef47fa01c748772"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.rexona.com/ua","noQueryString":true},
    },{
      plugin: require('../../common/gatsby-browser.tsx'),
      options: {"plugins":[],"algoliaAppId":"UTD3D68TKM","analyticsAdobeAnalyticsId":"unilever-webnetlify-rexona-ua","analyticsAdobeLaunchScriptName":"/e6bd1902389a/ce0b9473e95b/launch-d8d66ba8aace.min.js","analyticsCountry":"UA","analyticsGoogleAnalyticsId":"G-XDJ17WXVS3","analyticsLanguage":"uk_UA","analyticsLocalBrand":"Rexona","contactUsBrandId":"BH0413","contactUsFormType":"twh","contactUsLocale":"uk-UA","contactUsQCDeployment":"","contactUsQCLiveAgentInit":"","contactUsQCDeploymentId":"5720L0000008dXQ","contactUsQCOrgId":"00DE0000000bbLj","fontsPath":"/ua/fonts","htmlLang":"uk-UA","isoCountry":"UA","isoLanguage":"UK","kritiqueApiKey":"","kritiqueBrandId":"","kritiqueLocaleId":"uk_ua","kritiqueRegion":"","kritiqueSiteSource":"Rexona-uk-ua","locale":"uk_UA","oneTrustDataDomainScriptId":"63316c87-0194-46ed-8bcb-327ef46dcb78","mikmakAppId":"","prmApiUrl":"","sanityId":"27438tds","siteMapExclusions":["/preview","/profile","/forgotten-password","/logout","/edit-profile"],"siteName":"Rexona","smartLabel":false,"searchV2":true,"youtubeApiIframe":"https://www.youtube.com/iframe_api","additionalLanguagesConfig":[{"language":"","analyticsLanguage":"","contactUsLocale":"","contactUsBrandId":"","htmlLang":"","isoLanguage":"","epsilonDataSourceId":"","epsilonBrandCCID":"","kritiqueApiKey":"","kritiqueLocaleId":"","kritiqueSiteSource":"","locale":""}],"binConstCo":"","binCartWire":"","contactUsQCButtonId":"","cognitoPoolWebClientId":"","cognitoRegion":"","cognitoUserPoolId":"","cookieQscriptURL":"","cookieQlang":"","cookieQORG":"","epsilonUrl":"","epsilonDataSourceId":"","epsilonSignUpCCID":"","epsilonBrandCCID":"","epsilonCorporateCCID":"","evidonCompanyID":"","evidonOCID":"","extendedProfileApiUrl":"","miappiClientId":"","prmBrandCode":"","prmCampaignId":"","reCaptchaSiteKey":"","useShopalyst":{"link":"","campaignId":0,"placementId":0}},
    }]
