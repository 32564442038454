import React, { FunctionComponent } from "react";
import { globalHistory, Location } from "@reach/router";
import { QueryParamProvider } from "use-query-params";
import ClickTracker from "web-common/src/components/Analytics/ClickTracker";
import ScrollTracker from "web-common/src/components/Analytics/ScrollTracker";
import RedirectHandler from "../RedirectHandler";

const App: FunctionComponent = ({ children }) => {
  return (
    <>
      <Location>
        {({ location }) => (
          <>
            <RedirectHandler location={location} />
            <QueryParamProvider location={location} reachHistory={globalHistory}>
              <ScrollTracker></ScrollTracker>
              <ClickTracker>{children}</ClickTracker>
            </QueryParamProvider>
          </>
        )}
      </Location>
    </>
  );
};

export default App;
